const dummyPdfBase64 =
  "JVBERi0xLjMKJcTl8uXrp/Og0MTGCjQgMCBvYmoKPDwgL0xlbmd0aCA1IDAgUiAvRmlsdGVyIC9GbGF0ZURlY29kZSA+PgpzdHJlYW0KeAErVAgEAAHnAOMKZW5kc3RyZWFtCmVuZG9iago1IDAgb2JqCjExCmVuZG9iagoyIDAgb2JqCjw8IC9UeXBlIC9QYWdlIC9QYXJlbnQgMyAwIFIgL1Jlc291cmNlcyA2IDAgUiAvQ29udGVudHMgNCAwIFIgL01lZGlhQm94IFswIDAgNjEyIDc5Ml0KL1JvdGF0ZSAwID4+CmVuZG9iago2IDAgb2JqCjw8IC9Qcm9jU2V0IFsgL1BERiBdID4+CmVuZG9iagozIDAgb2JqCjw8IC9UeXBlIC9QYWdlcyAvTWVkaWFCb3ggWzAgMCA2MTIgNzkyXSAvQ291bnQgMSAvS2lkcyBbIDIgMCBSIF0gPj4KZW5kb2JqCjcgMCBvYmoKPDwgL1R5cGUgL0NhdGFsb2cgL1BhZ2VzIDMgMCBSID4+CmVuZG9iagoxIDAgb2JqCjw8IC9Qcm9kdWNlciAobWFjT1MgVmVyc2lvbiAxMC4xNS43IFwoQnVpbGQgMTlIMlwpIFF1YXJ0eiBQREZDb250ZXh0KSAvQ3JlYXRpb25EYXRlCihEOjIwMjEwMTI4MjIxNjA3WjAwJzAwJykgL01vZERhdGUgKEQ6MjAyMTAxMjgyMjE2MDdaMDAnMDAnKSA+PgplbmRvYmoKeHJlZgowIDgKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwNDEwIDAwMDAwIG4gCjAwMDAwMDAxMjUgMDAwMDAgbiAKMDAwMDAwMDI3OCAwMDAwMCBuIAowMDAwMDAwMDIyIDAwMDAwIG4gCjAwMDAwMDAxMDcgMDAwMDAgbiAKMDAwMDAwMDIzOSAwMDAwMCBuIAowMDAwMDAwMzYxIDAwMDAwIG4gCnRyYWlsZXIKPDwgL1NpemUgOCAvUm9vdCA3IDAgUiAvSW5mbyAxIDAgUiAvSUQgWyA8MzFkOGZlZjY0OTQ1MWI4ZmY1N2YyODQ3MWU4MzFjODU+CjwzMWQ4ZmVmNjQ5NDUxYjhmZjU3ZjI4NDcxZTgzMWM4NT4gXSA+PgpzdGFydHhyZWYKNTczCiUlRU9GCg==";

export const dummyPdfBase64Url = `data:application/pdf;base64,${dummyPdfBase64}`;

export const dummyPdfArrayBuffer = Uint8Array.from(
  window.atob(dummyPdfBase64),
  (c) => c.charCodeAt(0),
);
export const dummyPdfFile = new File([dummyPdfArrayBuffer], "dummy.pdf", {
  type: "application/pdf",
});
