export const theme = {
  space: [0, 8, 10, 16, 20, 24, 30, 40, 50, 60, 70, 80, 90, 100],

  radii: {
    none: "0",
    sm: "0.125rem",
    base: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },

  fontSizes: {
    xxl: 28,
    xl: 24,
    lg: 18,
    md: 16,
    default: 16,
    sm: 14,
    xsm: 12,
  },

  fontWeights: {
    light: 300,
    normal: 400,
    medium: 600,
    bold: 700,
    heavy: 900,
  },

  colors: {
    brand: {
      horizon: "#FF7575",
      spruce: "#0F3647",
    },
    gray: {
      50: "#ffffff",
      100: "#fafafa",
      200: "#f5f5f5",
      300: "#f0f0f0",
      400: "#d9d9d9",
      500: "#bfbfbf",
      600: "#8c8c8c",
      700: "#595959",
      800: "#434343",
      900: "#262626",
    },
  },
};
