import * as Sentry from "@sentry/react";
import { useAuth } from "../auth";
import { useEffect } from "react";

class SentryApiError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "ApiError";
  }
}

function getRequestId(headers: HeadersInit | undefined) {
  const headerName = "X-Request-ID";
  if (headers) {
    if (headers instanceof Headers) {
      return headers.get(headerName);
    } else if (Array.isArray(headers)) {
      return headers.find(([k, _v]) => k === headerName)?.at(1);
    } else {
      return headers[headerName];
    }
  }
}

async function logApiErrorResponse(
  config: RequestInit | undefined,
  resp: Response,
) {
  const { headers, status, ok, url } = resp;
  if (ok) {
    return;
  }
  let body: unknown;
  try {
    body = JSON.stringify(await resp.json());
  } catch {}
  const isFromWaf = headers.get("server")?.includes("awselb");
  const result = {
    url,
    headers: Object.fromEntries(headers),
    status,
    body,
    isFromWaf,
  };
  const appName = url.includes(process.env.VITE_ESOURCE_API_ROOT as string)
    ? "esource"
    : "doa";
  const requestId = getRequestId(config?.headers);
  // TODO: integrate with DataDog
  Sentry.withScope((scope) => {
    scope.setFingerprint(["{{default}}", appName, "API", url]);
    scope.setTags({ app: appName, errorType: "API", requestId });
    scope.setContext("data", result);
    Sentry.captureException(new SentryApiError(url));
  });
}

export function initSentry(opts: { transport?: any } = {}) {
  const defaultOptions = {
    dsn: process.env.VITE_SENTRY_DSN,
    release: process.env.VITE_SENTRY_RELEASE,
    environment: process.env.VITE_ENV,
  };
  if (process.env.VITE_SENTRY_DSN) {
    Sentry.init({ ...defaultOptions, ...opts });
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
      const [resource, config] = args;
      // request interceptor here
      const response = await originalFetch(resource, config);
      // response interceptor here
      await logApiErrorResponse(config, response.clone());
      return response;
    };
  }
}

export const SentryErrorBoundary = ({
  children,
  appName,
}: {
  children: JSX.Element;
  appName: "doa" | "esource";
}) => {
  const auth = useAuth();
  const user = auth?.user;
  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.sub, email: user.email });
    }
  }, [user]);
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTags({ app: appName, errorType: "ui-render" });
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
