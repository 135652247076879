import {
  message,
  notification,
  ArgsProps,
  NotificationApi,
  MessageApi,
} from "@reifyhealth/picasso-pkg";
import { Notifier } from "../qc/types";

type Options = Omit<ArgsProps, "description">;

/**
 * Implementation
 */

class DefaultNotifier implements Notifier {
  private readonly messageApi: MessageApi;
  private readonly notificationApi: NotificationApi;

  constructor() {
    this.messageApi = message;
    this.notificationApi = notification;
  }

  error(content: React.ReactNode, options?: Options) {
    this.notificationApi.error({
      message: "Error",
      description: content,
      ...options,
    });
  }

  info(content: React.ReactNode) {
    this.messageApi.info(content);
  }

  success(content: React.ReactNode) {
    this.messageApi.success(content);
  }

  warning(content: React.ReactNode, options?: Options) {
    this.notificationApi.warning({
      message: "Warning",
      description: content,
      ...options,
    });
  }
}

/**
 * AntD configuration
 */

message.config({
  duration: 5,
});

notification.config({
  duration: 0,
  placement: "topRight",
  top: 60,
});

/**
 * Exports
 */

export const notifier = new DefaultNotifier();
