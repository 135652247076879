import { Signature, SignatureInvalidation } from "../signatures/types";
import { QueryType } from "../qc/types";

export type AuthorName = string;

export type Author = {
  name: AuthorName;
  firstSavedAt: string;
};

export type AuthorNames = AuthorName[];

export enum DocumentTypes {
  ScannedVerified = "scanned-verified",
  ScannedUnverified = "scanned-unverified",
  DDCComplete = "direct-capture-complete",
  DDCPartial = "direct-capture-partial",
  DDC = "ddc",
  Upload = "upload",
  Unknown = "",
}

export const parseDocumentType = (documentType: string): DocumentTypes =>
  documentType === DocumentTypes.DDC.valueOf()
    ? DocumentTypes.DDC
    : documentType === DocumentTypes.Upload.valueOf()
      ? DocumentTypes.Upload
      : documentType === DocumentTypes.DDCPartial.valueOf()
        ? DocumentTypes.DDCPartial
        : documentType === DocumentTypes.DDCComplete.valueOf()
          ? DocumentTypes.DDCComplete
          : documentType === DocumentTypes.ScannedUnverified.valueOf()
            ? DocumentTypes.ScannedUnverified
            : documentType === DocumentTypes.ScannedVerified.valueOf()
              ? DocumentTypes.ScannedVerified
              : DocumentTypes.Unknown;

/**
 * QC Workflow for Documents
 */
export enum DocumentWorkflowStatus {
  ReadyForReview = "ready-for-review",
  InReview = "in-review",
  Approved = "approved",
  ReviewNotRequired = "review-not-required",
}

/**
 * Request Update for Documents
 */
export interface UpdateRequest {
  user: {
    id: string;
    email: string;
    name: string;
    fullName?: string;
  };
  timestamp: string;
  note: string | null;
}

export type DocumentAssignee = {
  id: string;
  email: string;
  name: string;
};

export enum DocumentQueryStatus {
  Open = "open",
  Resolved = "closed",
}

// API response doesn't return an id field.
export type DocumentQueryComment = {
  id: string;
  createdAt: string;
  modifiedAt: string | null;
  systemGenerated: boolean;
  text: string;
  userId: string;
  userName: string;
};

export type DocumentQuery = {
  id: string;
  age: number;
  comments: DocumentQueryComment[];
  createdAt: string;
  documentId: string;
  documentName: string;
  formFieldId: string;
  modifiedAt: string;
  origin: "internal" | "external";
  status: DocumentQueryStatus;
  type: QueryType;
  userId: string;
  userName: string;
};

export type DocumentQueryListItem = Omit<
  DocumentQuery,
  "userId" | "userName" | "modifiedAt" | "comments"
> & {
  documentName: string;
  subjectId: string;
  createdBy: string;
  createdByName: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  age: number;
  filledInFormId: string;
  siteTrialPatientId: string;
  type: string;
};

/**
 * Document data from the API
 */
export type DocumentData = Partial<{
  authors?: Author[];
  confirmed: boolean;
  closedQueriesCount?: number;
  documentType: DocumentTypes;
  fileSize?: number;
  updatedAt?: string | null;
  filledInFormId?: string | null;
  formId?: string | null;
  openQueriesCount?: number;
  originalDate: string | null;
  pageCount?: number;
  partial?: boolean;
  piSignatureRequired: boolean;
  piSignatureInvalidated: boolean;
  piUpdatesRequested: boolean;
  protocolScheduleVersionVisitId: string;
  protocolVersion?: string | null;
  qcWorkflowStatus?: DocumentWorkflowStatus | null;
  updateRequest: UpdateRequest | null;
  reviewer?: DocumentAssignee | null;
  revisionAssignee?: DocumentAssignee | null;
  signatureInvalidations: SignatureInvalidation[];
  trialSource: string;
  lifeCycleStatus?: string | null;
  /* @deprecated */
  unscheduledVisitId: string;
  uploader: string;
  uploaderEmail: string;
  version: number;
  visitId: string;
  workflowAge: number;
  workflowStatus: string;
  visitName?: string | null;
  trackName?: string | null;
}> & {
  patientVisitIds: (string | undefined)[];
  siteTrialPatientId: string;
  status: string;
  createdAt?: string;
  updatedAt?: string;
  type: DocumentTypes;
  signed: boolean;
  signatures: Signature[];
  piSigned?: boolean;
  userCanApproveAndSign?: boolean;
  uploadedAt?: string | null;
  subjectId: string;
  id: string;
  name: string;
  patientVisits: Array<
    IPatientProtocolVisit | IUnscheduledPatientProtocolVisit
  >;
};

export type DocumentDataWithModifiedAt = Omit<DocumentData, "updatedAt"> & {
  documentModifiedAt?: string | null;
};

export type TableDocument = DocumentData & {
  trialId: string;
  siteId: string;
  submittedAt: string;
  download: () => Promise<void>;
  view: () => Promise<void>;
};

export type DocumentWorkflowUpdateResponseData = {
  id: string;
  filledInFormId?: string | null;
  documentType?: DocumentTypes;
  name: string;
  subjectId: string;
  siteTrialPatientId: string;
  uploaderId: string;
  uploadedAt: string;
  trialSource?: string;
  originalDate: string | null;
  status: string;
  signed: boolean;
  confirmed: boolean;
  piSigned?: boolean;
  piSignatureRequired: boolean;
  qcWorkflowStatus?: DocumentWorkflowStatus | null;
  reviewer?: DocumentAssignee | null;
  aggregationCounts?: {
    allDocuments: number;
    myAssignments: number;
    unassignedDocuments: number;
  };
};
