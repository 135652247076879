import { type ReactNode, useCallback } from "react";
import type { AppState } from "@auth0/auth0-react";
import { Auth0 } from "@reifyhealth/picasso-auth0";
import { isDevEnabled } from "@app/lib/environment";
import { Auth0ConfigTypes } from "@types";
import config from "../../config";
import { GRAPHQL_URL } from "@app/constants";
import { QueryClient } from "react-query";
import { useNavigate } from "@app/hooks/router";

type Prop = {
  children: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const Auth0ProviderWithHistory = ({ children }: Prop) => {
  const { domain, clientID, scope, audience, issuer }: Auth0ConfigTypes =
    config.auth0ServiceConfig();

  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: AppState | undefined) => {
      navigate(appState?.returnTo || "/");
    },
    [navigate],
  );

  return (
    <Auth0
      api={GRAPHQL_URL}
      personaId={undefined}
      domain={domain}
      issuer={issuer}
      queryClient={queryClient}
      clientId={clientID}
      authorizationParams={{
        redirect_uri: isDevEnabled()
          ? window.location.origin
          : `${window.location.origin}/documents`,
        audience,
        scope,
      }}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
      datadogRum={{
        applicationId: "b0c42214-af8e-448f-9ec6-7b4dbe6804f2",
        clientToken: "pubb8005fd7e632d8e1b100c1aa231123ec",
        site: "datadoghq.com",
        service: "study-sheet",
        version: process.env.VITE_VERSION,
        env: process.env.VITE_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [
          {
            match: /https:\/\/.*(?<!auth)\.studyteamapp\.com/,
            propagatorTypes: ["datadog"],
          },
        ],
      }}
      // FIXME: Source leverages LD uniquely which is not currentl supported by Picasso
      // launchDarkly={{
      //     clientSideID: launchDarklyClientID,
      //     overridesEnabled: !ENVIRONMENTS.isProduction,
      // }}
    >
      {children}
    </Auth0>
  );
};

export { Auth0ProviderWithHistory };
