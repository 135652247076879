/**
 * @deprecated This enum should not be used anymore. Please see FGA_PERMISSIONS
 */
export enum Action {
  AuditEvents = "view_esource_audit_events",
  AuditRoleChanges = "view_esource_audit_role_changes",
  DownloadDocument = "download_esource_document",
  ManageSignatureAssignments = "esource_manage_signature_assignments",
  MonitorReview = "monitor_review_esource",
  OpenCloseInternalQuery = "open_close_internal_esource_query",
  QCReview = "qc_review_esource",
  RenameDocument = "rename_esource_document",
  RespondInternalQuery = "respond_to_internal_esource_query",
  SignAsPI = "sign_as_pi_esource_document",
  UpdateSubjectId = "update_subject_id_on_esource_document",
  UploadDocument = "upload_esource_documents",
  ViewDocument = "view_esource_documents",
  ViewInternalQuery = "view_internal_esource_query",
  ViewSignatureAssignments = "esource_view_signature_assignments",
  ViewSubjectDateOfBirth = "view_patient_dob",
  ViewSubjectName = "view_patient_name",
}
