import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/browser";
import { notifier } from "./features/notifications";

/**
 * Application wide errors
 */

/**
 * Types
 */

type AppErrorOptions = {
  remainStack?: boolean;
  fromServer?: boolean;
  title?: string;
};

/**
 * User friendly messages
 */

export const BAD_GATEWAY_ERROR_MSG =
  "The server is currently unavailable because it is overloaded or down for maintenance.";

export const UNEXPECTED_ERROR_MSG =
  "Oops! Something went wrong! Help us improve your experience by sending an error report.";

export const SERVICE_UNAVIALABLE_ERROR_MSG =
  "The server is currently unavailable because it is overloaded or down for maintenance.";

export const TIMEOUT_ERROR_MSG =
  "Looks like the server is taking to long to respond, this can be caused by either poor connectivity or an error with our servers. Please try again in a while.";

const ALB_FORBIDDEN_ERROR_MSG =
  "Your request cannot be processed at this moment due to data protection limitations.";

/**
 * Definitions
 */

export class AppError extends Error {
  options?: AppErrorOptions;

  constructor(message?: string, options?: AppErrorOptions) {
    super(message);
    if (!options?.remainStack) {
      this.stack = undefined;
    }
    this.options = options;
  }
}

export class UnexpectedError extends AppError {
  constructor(
    message: string = UNEXPECTED_ERROR_MSG,
    options?: AppErrorOptions,
  ) {
    super(message, options);
  }
}

export class PermissionDeniedError extends AppError {
  constructor(message: string = "Permission denied") {
    super(message);
  }
}

export class NotFoundError extends AppError {
  constructor(message: string = "Resource not found") {
    super(message);
  }
}

export class SessionExpiredError extends AppError {
  constructor() {
    super("Session expired");
  }
}

export class InvalidOperationError extends AppError {
  constructor(message: string = "Invalid operation") {
    super(message);
  }
}

export class SubjectNotFoundError extends AppError {
  constructor() {
    super("Subject not found");
  }
}

export class DocumentsNotFoundError extends AppError {
  constructor() {
    super("Documents not found");
  }
}

export class ServerError extends AppError {}

export class UnexpectedServerError extends ServerError {
  constructor(
    message: string = UNEXPECTED_ERROR_MSG,
    options?: AppErrorOptions,
  ) {
    super(message, options);
  }
}

export class BadGatewayError extends ServerError {
  constructor(
    message: string = BAD_GATEWAY_ERROR_MSG,
    options?: AppErrorOptions,
  ) {
    super(message, options);
  }
}

export class ServiceUnavailableError extends ServerError {
  constructor(
    message: string = SERVICE_UNAVIALABLE_ERROR_MSG,
    options?: AppErrorOptions,
  ) {
    super(message, options);
  }
}

export class TimeoutError extends ServerError {
  constructor(message: string = TIMEOUT_ERROR_MSG, options?: AppErrorOptions) {
    super(message, options);
  }
}

export class ALBForbiddenError extends ServerError {
  constructor(
    message: string = ALB_FORBIDDEN_ERROR_MSG,
    options?: AppErrorOptions,
  ) {
    super(message, options);
  }
}

export const report = (err: Error, context?: object) => {
  console.error(err);
  if (context) {
    console.error(context);
  }
  datadogRum.addError(err, context);
  Sentry.captureException(err);
};

export const defaultOnError = (err: Error) => {
  const userMessage = UNEXPECTED_ERROR_MSG;
  report(err, { userMessage });
  notifier.error(userMessage);
};
