import { useEffect, useState } from "react";

export function useConsentState() {
  const [consentState, setConsentState] = useState<CookieConsentState>(
    window.CookieConsent?.consent ?? null,
  );

  function handleConsentReady() {
    setConsentState(window.CookieConsent.consent);
  }

  useEffect(() => {
    window.addEventListener("CookiebotOnConsentReady", handleConsentReady);
    return () => {
      window.removeEventListener("CookiebotOnConsentReady", handleConsentReady);
    };
  }, []);

  return consentState;
}
