import { defaultConfigValues } from "@app/esource/test-utils";
import {
  mockListFgaRolesQuery,
  mockListFgaSiteTrialsQuery,
  mockListRoleAssignmentsQuery,
  mockListSourceEnabledUserSiteTrialsQuery,
  mockSourceAccessInfoQuery,
} from "@app/service/generated";
import { rest } from "msw";
import { Action } from "@app/esource/features/users/actions";
import {
  DocumentTypes,
  DocumentWorkflowStatus,
} from "@app/esource/features/document-tables/types";
import { allQCDocuments } from "@app/esource/mocks/data/qc-documents";
import { dummyPdfArrayBuffer } from "@app/esource/mocks/data/dummy-pdf";

const samaritanSiteId = "a6b4e4e8-db40-5ff8-81f5-77b81874461f";
const careSiteId = "4f7ccc70-6b86-593c-9629-c9515911ec7e";
const westfordGeneralSiteId = "15e9da84-f8ed-5db2-b417-3c3a9eb84614";

const baseUrl = defaultConfigValues.esourceApiRoot;
const documentsMock = [
  {
    fileSize: 58145,
    uploadedAt: "2022-03-28T13:25:15.406610Z",
    subjectId: "1001",
    authors: [
      { firstSavedAt: "2022-03-28T13:25:15.406610Z", name: "Nick Moorehead" },
    ],
    uploader: "Nick Moorehead",
    name: "Concomitant Medication Log",
    level2: null,
    confirmed: true,
    status: "no QR code",
    id: "45ba0005-6967-479a-bd48-281ffd705193",
    siteTrialPatientId: "5e670806-8fb0-4de1-93ed-69034a0a03a7",
    pageCount: 1,
    level0: null,
    piSigned: false,
    piSignatureRequired: false,
    level1: null,
    originalDate: null,
    signed: true,
  },
  {
    fileSize: 12617,
    uploadedAt: "2022-03-25T18:40:09.098702Z",
    subjectId: "1002",
    authors: [
      { firstSavedAt: "2022-03-25T18:40:09.098702Z", name: "Benjamin Aplin" },
    ],
    uploader: "Benjamin Aplin",
    name: "Adverse Event Log",
    level2: null,
    confirmed: true,
    status: "no QR code",
    id: "25d66c1b-4c8e-40fd-9f13-965b17f9495e",
    siteTrialPatientId: "1aa6a28d-10e8-4e0f-8f2b-13cb4088f4d0",
    pageCount: 1,
    level0: null,
    piSigned: true,
    piSignatureRequired: false,
    level1: null,
    originalDate: null,
    signed: true,
  },
  {
    fileSize: 84041,
    uploadedAt: "2022-03-11T00:58:22.045013Z",
    subjectId: "1002",
    authors: [
      {
        firstSavedAt: "2022-03-11T00:58:22.045013Z",
        name: "Harsha Chilakamarri - SiteUser",
      },
    ],
    uploader: "Harsha Chilakamarri - SiteUser",
    name: "Concomitant Medication Log",
    level2: null,
    confirmed: true,
    status: "ok",
    id: "2b52ea54-66b5-4425-8f0f-aff8c79fd4f4",
    siteTrialPatientId: "1aa6a28d-10e8-4e0f-8f2b-13cb4088f4d0",
    pageCount: 1,
    level0: null,
    piSigned: false,
    piSignatureRequired: false,
    level1: null,
    originalDate: null,
    signed: true,
  },
  {
    fileSize: 87800,
    uploadedAt: "2022-03-11T00:58:22.045013Z",
    subjectId: "1002",
    authors: [
      {
        firstSavedAt: "2022-03-11T00:58:22.045013Z",
        name: "Harsha Chilakamarri - SiteUser",
      },
    ],
    uploader: "Harsha Chilakamarri - SiteUser",
    name: "Adverse Event",
    level2: null,
    confirmed: true,
    status: "ok",
    id: "77ba643b-6edd-4311-8c28-eccf7dc920c6",
    siteTrialPatientId: "1aa6a28d-10e8-4e0f-8f2b-13cb4088f4d0",
    pageCount: 2,
    level0: null,
    piSigned: false,
    piSignatureRequired: false,
    level1: null,
    originalDate: null,
    signed: true,
  },
  {
    fileSize: 84041,
    uploadedAt: "2022-03-10T15:24:26.171341Z",
    subjectId: "1002",
    authors: [
      {
        firstSavedAt: "2022-03-10T15:24:26.171341Z",
        name: "Harsha Chilakamarri - SiteUser",
      },
    ],
    uploader: "Harsha Chilakamarri - SiteUser",
    name: "Concomitant Medication Log",
    level2: null,
    confirmed: true,
    status: "ok",
    id: "b33ba589-cb2e-4d4b-bbc9-f53eca31ca84",
    siteTrialPatientId: "1aa6a28d-10e8-4e0f-8f2b-13cb4088f4d0",
    pageCount: 1,
    level0: null,
    piSigned: false,
    piSignatureRequired: false,
    level1: null,
    originalDate: "2022-03-08",
    signed: true,
  },
  {
    confirmed: true,
    createdAt: "2022-05-04T16:27:59.526313Z",
    filledInFormId: "a04a483f-e63a-48cb-a750-11eaf82532e6",
    id: "b7e6bb20-3883-45ec-810f-4539aaa5d490",
    level0: null,
    level1: null,
    level2: null,
    name: "Demographics",
    originalDate: null,
    piSigned: true,
    piSignatureRequired: false,
    signed: false,
    siteTrialPatientId: "7e0e7b93-d786-40ef-8854-9a5f429cc424",
    status: null,
    subjectId: "1003",
    type: DocumentTypes.DDCComplete,
    updatedAt: "2022-05-04T16:28:16.936946Z",
    uploadedAt: "2022-05-04T16:27:59.526313Z",
    authors: [
      { firstSavedAt: "2022-05-04T16:27:59.526313Z", name: "Benjamin Aplin" },
    ],
    uploader: "Benjamin Aplin",
  },
  {
    confirmed: true,
    createdAt: "2022-05-04T16:20:30.971832Z",
    filledInFormId: "fddb0663-c779-410c-8ff9-844863c00d73",
    id: "32ab9f87-3038-4198-abcf-bd61b499dd67",
    level0: null,
    level1: null,
    level2: null,
    name: "Demographics",
    originalDate: null,
    piSigned: true,
    piSignatureRequired: false,
    signed: false,
    siteTrialPatientId: "7e0e7b93-d786-40ef-8854-9a5f429cc424",
    status: null,
    subjectId: "1003",
    type: DocumentTypes.DDCComplete,
    qcWorkflowStatus: DocumentWorkflowStatus.InReview,
    updatedAt: "2022-05-04T16:20:50.080220Z",
    uploadedAt: "2022-05-04T16:20:30.971832Z",
    authors: [
      { firstSavedAt: "2022-05-04T16:20:30.971832Z", name: "Benjamin Aplin" },
    ],
    uploader: "Benjamin Aplin",
  },
  ...allQCDocuments,
];

const auth0ReauthHandler = rest.post(
  "*/oauth/token",
  async (req: { body: { password: string } }, res, ctx) => {
    if (req.body?.password === "wrong password") {
      return res(
        ctx.status(403),
        ctx.json({
          errorMessage: `unauthorized`,
        }),
      );
    }

    return res(
      ctx.status(200),
      ctx.json({
        access_token:
          "eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly90ZXN0aW5nLXVzLmF1dGguc3R1ZHl0ZWFtYXBwLmNvbS8ifQ..rXn0YmFP9EJ_NBHd.zTi5Z4SQpm5VdJFCuOHTNkRQt4ueUoYXYFJ6vKxkfA-1DcJkUebzQsVYgQNvIzswdzQ5-4fEUCfiHCto2uM42A49WqS7qmvOjiluhD9eg-CdPjUxbDCxbgwYb2C1rugmhCKjv551xy6dOTuYH8So8pvPxdAxuVxxhr-VCQLldxRYx0I-3PNnDjReOcEkFDzJztFgc5awENp47eoNQvV9P9jDZdCD9yuWEA7omDZk8V-Pb40W-zT7OdOl_vytNvKzu_QdYBpeldZkvw-OqP__HVLDH6sLPEywRigsECAP2sGN-rsYNAtIhnhbRXeSs4_zfs2cy_-GcXywrL5uTOBMyXhvyRdW7PZCeX9zojhrPomOBJ5NVDZMS5885COn3p718ngKCSJZoqVqzBhF0kioNv8l0IEnNFhxEyPBOSW2zUUYCJQ8mknReCG0veAtNpvJFBGTnbeyR7HgnpILnzbYfT49eRX6SvVWyD-RRwsrzLeE99BUeQJKggby3bMMlB9JUG78K9nEx5ynCVIIg0pYpFxKvlQu7YuUJdyT3rPiM3KadK81vpkudi4.O-GJvKzzBPorzURks494RA",
        idToken:
          "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjFLbDlzeFM1cS1XbmVQVFdwOW5lVCJ9.eyJodHRwczovL3N0dWR5dGVhbWFwcC5jb20vdXNlci1pZCI6ImF1dGgwfDYxZjJjZGJkMjUzMzcyMDA3MDNhY2ZlYSIsImh0dHBzOi8vc3R1ZHl0ZWFtYXBwLmNvbS9yb2xlIjoibm9uZSIsIm5pY2tuYW1lIjoiYmVuamFtaW4uYXBsaW4iLCJuYW1lIjoiYmVuamFtaW4uYXBsaW5AcmVpZnlmYW0uY29tIiwicGljdHVyZSI6Imh0dHBzOi8vcy5ncmF2YXRhci5jb20vYXZhdGFyLzIwNGEwMWFhNjZhMGIyNDI2NzdkYTE3ZjFjOGE2OTZhP3M9NDgwJnI9cGcmZD1odHRwcyUzQSUyRiUyRmNkbi5hdXRoMC5jb20lMkZhdmF0YXJzJTJGYmUucG5nIiwidXBkYXRlZF9hdCI6IjIwMjItMDQtMTRUMDE6NDE6MTMuODY3WiIsImVtYWlsIjoiYmVuamFtaW4uYXBsaW5AcmVpZnlmYW0uY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJpc3MiOiJodHRwczovL3Rlc3RpbmctdXMuYXV0aC5zdHVkeXRlYW1hcHAuY29tLyIsInN1YiI6ImF1dGgwfDYxZjJjZGJkMjUzMzcyMDA3MDNhY2ZlYSIsImF1ZCI6ImVqUmVKdkE1VG9aY1JqWkMyZjNTeUs0V0hxU0RYcnN4IiwiaWF0IjoxNjQ5OTAwNDc0LCJleHAiOjE2NDk5MzY0NzR9.sTrXNpn6h1KuKjPwXhBWTtvB0YbTkzteFhO2u3LsJVEJAFfoJ4dFCQC49Cq2cqIDk8VbGNtjS09cqcRBbOLV0t_K81Y-qFj8w2eM-TAerJsQfyMWKGJmii-CXNddqPAMCk9xUp0LWv4w-_ysSCd1HNn4L_Tf3FEAmDii8fDmnaWRZh5X01M_GvBENTunxiLXG5GnQsDD-gLUzpQg3BUrmqb_ongQNFC9Ax1sZ5YYgDRpqKbxFT1H4oDQSYGOBJF4WJLONAJPigiHMRvyx57CcIt3zcFpqndkoram6FCEP5ZfBjJ1Rw97JF8l4rRWIQ_IUFFNZ0_1Su-HsJ9-oSCgbA",
        scope: "openid profile email",
        expires_in: 86400,
        token_type: "Bearer",
      }),
    );
  },
);

const trials = [
  {
    id: "5f17265a-1e2c-53ec-b47e-a0c95bd96bb1",
    name: "Athena",
    sites: [
      {
        id: westfordGeneralSiteId,
        name: "Westford General",
        number: "1111",
      },
      {
        id: careSiteId,
        name: "CARE Site 1",
        number: "10",
      },
      {
        id: samaritanSiteId,
        name: "Samaritan Research",
        number: null,
      },
    ],
  },
  {
    id: "6a0e4e83-9ae7-54f1-a40a-66213bf71d1e",
    name: "Beta",
    sites: [
      {
        id: careSiteId,
        name: "CARE Site 1",
        number: null,
      },
      {
        id: westfordGeneralSiteId,
        name: "Westford General",
        number: null,
      },
      {
        id: samaritanSiteId,
        name: "Samaritan Research",
        number: null,
      },
    ],
  },
  {
    id: "6cccdb3f-4909-5af2-bdd0-b52248aa21d1",
    name: "Medical Records",
    sites: [
      {
        id: careSiteId,
        name: "CARE Site 1",
        number: null,
      },
      {
        id: samaritanSiteId,
        name: "Samaritan Research",
        number: null,
      },
    ],
  },
];

const loginHandler = rest.post(`${baseUrl}/auth/login`, (_, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      message: "OK",
      trials,
      fullname: "Bob Apples",
      allowedActions: [
        Action.UploadDocument,
        Action.ViewDocument,
        Action.DownloadDocument,
        Action.ViewSubjectName,
        Action.RenameDocument,
        Action.ViewSubjectDateOfBirth,
        Action.AuditRoleChanges,
        Action.AuditEvents,
        Action.QCReview,
        Action.OpenCloseInternalQuery,
        Action.ViewInternalQuery,
      ],
    }),
  );
});

const emptyProfileHandler = rest.get(`${baseUrl}/profile`, (_req, res, ctx) =>
  res(ctx.json({})),
);

const trialId = "1";
const siteId = "2";
const siteTrialPatientId = "3";

const commonHandlers = [
  loginHandler,
  emptyProfileHandler,
  ...[
    rest.get(
      `${baseUrl}/trialId/siteId/subject/siteTrialPatientId`,
      async (_, res, ctx) => {
        return res(
          ctx.json({
            id: siteTrialPatientId,
            subjectId: `${siteTrialPatientId}_subjectId`,
            name: `${siteTrialPatientId}_name`,
            dob: `1990-01-01`,
          }),
        );
      },
    ),
    rest.get(
      `${baseUrl}/${trialId}/${siteId}/subject/${siteTrialPatientId}`,
      async (_, res, ctx) => {
        return res(
          ctx.json({
            id: siteTrialPatientId,
            subjectId: `${siteTrialPatientId}_subjectId`,
            name: `${siteTrialPatientId}_name`,
            dob: `1990-01-01`,
          }),
        );
      },
    ),
    rest.get(
      `${baseUrl}/${trialId}/${siteId}/subject/${siteTrialPatientId}/blank-form`,

      async (_, res, ctx) => {
        return res(
          ctx.json([
            {
              id: 1,
              name: "Adverse Event",
              versionLabel: "v2",
              tooltip: "tooltip text",
            },
            {
              id: 2,
              name: "Demographics",
              versionLabel: "1",
            },
            {
              id: 3,
              name: "Form 3",
              versionLabel: "V4",
              tags: ["TAG1"],
            },
          ]),
        );
      },
    ),
    rest.post(
      `${baseUrl}/${trialId}/${siteId}/document/sign`,
      async (_, res, ctx) => {
        return res(ctx.status(200));
      },
    ),
    rest.post(`${baseUrl}/signature/preparation`, (_, res, ctx) => {
      return res(ctx.status(200), ctx.json({ id: `test_sign_prep_token` }));
    }),
    rest.get(`*/${trialId}/${siteId}/document`, async (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(documentsMock));
    }),
    auth0ReauthHandler,
    rest.post(
      `${baseUrl}/getSignatureRequiredCount/v1`,
      async (_, res, ctx) => {
        return res(ctx.json({ data: { count: 1 } }));
      },
    ),
    rest.get(
      `*/${trialId}/${siteId}/${documentsMock[0].id}/download`,
      async (_, res, ctx) => {
        return res(
          ctx.set("Content-Length", dummyPdfArrayBuffer.byteLength.toString()),
          ctx.set("Content-Type", "application/pdf"),
          ctx.set("X-Pdf-File", "dummy.pdf"),
          ctx.body(dummyPdfArrayBuffer),
        );
      },
    ),
  ],
];

export const serverHandlers = [
  ...commonHandlers,
  mockListFgaSiteTrialsQuery((_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.data({
        Fga__listResources: {
          edges: [],
        },
      }),
    );
  }),
  mockListFgaRolesQuery((_, res, ctx) => {
    return res(ctx.status(200), ctx.data({ Fga__listRoles: { roles: [] } }));
  }),
  mockListRoleAssignmentsQuery((_, res, ctx) => {
    return res(ctx.status(200), ctx.data({ user: { roleAssignments: [] } }));
  }),
  mockSourceAccessInfoQuery((req, res, ctx) => {
    return res(ctx.data({ sourceAccessInfo: { hasAccess: true } }));
  }),
  mockListSourceEnabledUserSiteTrialsQuery((req, res, ctx) => {
    return res(
      ctx.data({
        listSourceEnabledUserSiteTrials: {
          edges: [
            {
              node: {
                studyteamId: "1234",
                name: "Test",
                site: null,
                sponsorTrial: null,
              },
            },
          ],
        },
      }),
    );
  }),
];
