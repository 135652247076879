import { SourceFlagSet, useLaunchDarklyFlags } from "@hooks/useFlags";
import * as err from "../error";
import { useConfig } from "../config";

function useFlag(flag: keyof SourceFlagSet) {
  const flags = useLaunchDarklyFlags();
  const config = useConfig();

  // Everything sans .env.individual.example is set to false
  if (process.env.ENABLE_FEATURE_FLAG_OVERRIDES === "true") {
    return (config.flags as any)[flag] || false;
  } else {
    return flags[flag] || false;
  }
}

const isForbiddenFromALB = (resp: Response) =>
  resp.status === 403 && resp.headers.get("server") === "awsalb/2.0";

export const throwWhenAPIError = async (resp: Response) => {
  if (resp.status === 400) {
    const json = await resp.json();
    throw new err.InvalidOperationError(json.error.message);
  }
  if (resp.status === 401) {
    throw new err.SessionExpiredError();
  }
  if (isForbiddenFromALB(resp)) {
    throw new err.ALBForbiddenError();
  }
  if (resp.status === 403) {
    throw new err.PermissionDeniedError(
      "You do not have permission to perform this action",
    );
  }
  if (resp.status === 404) {
    throw new err.NotFoundError();
  }
  if (resp.status === 500) {
    throw new err.UnexpectedServerError();
  }
  if (resp.status === 502) {
    throw new err.BadGatewayError();
  }
  if (resp.status === 503) {
    throw new err.ServiceUnavailableError();
  }
  if (resp.status === 504) {
    throw new err.TimeoutError();
  }
  throw new err.UnexpectedError();
};

// FIXME: Shouldn't we return all hooks from the `hooks` package?
export default useFlag;
