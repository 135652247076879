import {
  Auth0ContextInterface,
  User as Auth0User,
  IdToken,
  useAuth0,
} from "@auth0/auth0-react";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

type AuthProviderProps = {
  returnToPath?: string;
  children?: JSX.Element;
};

export type AuthProviderValue = {
  auth0: Auth0ContextInterface;
  user?: User;
  authError: () => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  rawToken?: string;
  setRawToken: Dispatch<SetStateAction<string | undefined>>;
  getAccessTokenSilently?: (options?: {
    detailedResponse?: boolean;
  }) => Promise<string>;
  isLoading: boolean;
  getIdTokenClaims?: () => Promise<IdToken | undefined>;
};

export const AuthState = React.createContext<AuthProviderValue | undefined>(
  undefined,
);

const STUDYTEAM_USER_ID_PROPERTY_NAME = "https://studyteamapp.com/user-id";

export interface User extends Auth0User {
  studyteamUserId: string;
}

const assignStudyteamUserId = (auth0User: Auth0User): User => {
  return {
    ...auth0User,
    studyteamUserId: auth0User[STUDYTEAM_USER_ID_PROPERTY_NAME] as string,
  };
};

/**
 * React higher order component providing auth context for child components.
 */
export const AuthProvider = ({ returnToPath, children }: AuthProviderProps) => {
  const auth0 = useAuth0();
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
  } = auth0;
  const [rawToken, setRawToken] = useState<string>();
  const getDefaultReturnToUrl = () => {
    const returnUrl = returnToPath
      ? `${process.env.VITE_PUBLIC_URL}${returnToPath}`
      : process.env.VITE_PUBLIC_URL;
    return new URL(returnUrl!, window.location.href).href;
  };
  const logout = () => {
    const returnTo = getDefaultReturnToUrl();
    return auth0.logout({ logoutParams: { returnTo } });
  };

  const providerValue: AuthProviderValue = {
    auth0,
    user: auth0.user ? assignStudyteamUserId(auth0.user) : auth0.user,
    authError: logout,
    logout,
    isAuthenticated,
    rawToken,
    setRawToken,
    getAccessTokenSilently,
    isLoading,
    getIdTokenClaims,
  };

  const getAuth0Token = useCallback(async () => {
    try {
      const claims = await getIdTokenClaims?.();
      if (claims?.__raw) {
        setRawToken(claims.__raw);
      }
    } catch (e) {
      console.error(e);
    }
  }, [getIdTokenClaims]);

  const getAuth0AccessToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently({ cacheMode: "off" });
      if (token) {
        setRawToken(token);
      }
    } catch (e) {
      console.error(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getAuth0Token();
  }, [getAuth0Token, auth0?.isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    getAuth0AccessToken();
  }, [getAuth0AccessToken, auth0?.isAuthenticated, getAccessTokenSilently]);

  return (
    <AuthState.Provider value={providerValue}>{children}</AuthState.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthState);
  if (context === undefined) {
    throw new Error("useAuth must be wrapped in AuthProvider");
  }
  return React.useContext(AuthState);
};
