import { useEffect } from "react";
import { useConsentState } from "./hooks/useConsentState";
import {
  initializePendo,
  updateOptions,
  getPendoInstance,
  isPendoAvailable,
} from "pendo/pendo";
import { useAuth } from "@app/hooks/auth";

/** Component used to initialize Pendo and set proper flag */
export const Pendo = () => {
  // eslint-disable-next-line
  const consentState = useConsentState();
  const auth = useAuth();

  useEffect(() => {
    if (consentState && isPendoAvailable() && auth?.user) {
      // eslint-disable-next-line
      initializePendo(getPendoInstance(), consentState, auth?.user.id);
    }
  }, [consentState, auth?.user]);

  useEffect(() => {
    if (consentState && isPendoAvailable() && !!auth?.user) {
      updateOptions(getPendoInstance(), auth?.user);
    }
  }, [consentState, auth?.user]);

  return null;
};

export default Pendo;
