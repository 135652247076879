import { useLDClient } from "launchdarkly-react-client-sdk";
import { User } from "@auth0/auth0-react";
import { ANONYMOUS_USER, getLDMultiContext } from "../common/featureFlags";
import { useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

type Context = {
  userId?: string;
  trialId?: string;
  anonymous: boolean;
};

export const parseTrialId = (path: string): string | undefined => {
  const match = path.match("^/documents/trials/([^/]+)");
  return match ? match[1] : undefined;
};

export function useLDIdentify(user?: User) {
  const [context, setContext] = useState<Context>({
    userId: ANONYMOUS_USER,
    anonymous: true,
  });

  const ldClient = useLDClient();
  if (!ldClient) {
    return;
  }
  const trialId = parseTrialId(location.pathname);

  const contextChanged =
    context.userId !== (user?.sub ?? ANONYMOUS_USER) ||
    context.trialId !== trialId;

  const newUser = user?.sub ?? ANONYMOUS_USER;
  const newAnon = !newUser;

  if (contextChanged) {
    ldClient
      .identify(
        getLDMultiContext(newAnon ? { sub: ANONYMOUS_USER } : user, trialId),
      )
      .catch(() => {
        // silently fail
      });
    setContext({ userId: newUser, trialId, anonymous: newAnon });
  }
}

export function useDatadogUser(user?: User) {
  datadogRum.setUser({
    id: user?.sub,
    email: user?.email,
    name: user?.name,
  });
}

export { default as useFlags } from "./useFlags";
