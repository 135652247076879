import { LogoutOptions, User } from "@auth0/auth0-react";
import { useAuth0 } from "@reifyhealth/picasso-auth0";

interface SourceUser {
  id: string;
  email: string;
  studyteamUserId: string;
}

export type SourceAuthResult = Pick<
  ReturnType<typeof useAuth0>,
  | "logout"
  | "getAccessTokenSilently"
  | "isAuthenticated"
  | "isLoading"
  | "error"
  | "loginWithRedirect"
> & {
  user: SourceUser;
};

/**
 * Return a SourceUser derived from the Auth0 hook user data.
 *
 * The sub is the Auth0 ID (the idp_id).
 * The https://studyteamapp.com/user-id claim is the PK user/id
 * from the User table. For some legacy people these are the same.
 * For others the User/id looks like a sub because we just kept
 * those around (it used to be that User/id and idp_id were one and the same).
 * When we did SSO we separated them out and just kept existing ID's the same
 * for consistency and treated them as opaque string identifiers.
 * For any new users we now use UUIDs for the User/id and the idp_id is the
 * semantically meaningful one to link to the Auth0 user.
 */
const sourceUser: (
  auth0User: ReturnType<typeof useAuth0>["user"],
) => SourceUser & User = (auth0User) => {
  if (!auth0User) {
    return {
      id: "unknown",
      email: "unknown",
      studyteamUserId: "unknown",
    };
  }

  return {
    ...auth0User,
    id:
      auth0User["https://studyteamapp.com/user-id"] ||
      auth0User.sub ||
      "unknown",
    studyteamUserId:
      auth0User["https://studyteamapp.com/user-id"] ||
      auth0User.sub ||
      "unknown",
    email: auth0User.email || "unknown",
  };
};

const wrapDefaultRedirectTo =
  (logout: (options?: LogoutOptions) => Promise<void>, returnTo?: string) =>
  (options?: LogoutOptions) =>
    logout({
      ...options,
      logoutParams: {
        returnTo,
        ...options?.logoutParams,
      },
    });

/**
 * Application-level wrapper for Auth0 integration.
 *
 * This is intended to hide details of how Auth0 data maps to our own concept of
 * a user. For example, there are multiple attributes on an Auth0 user that we
 * need to consider for user IDs, but we only care about the end result from
 * the component's perspective.
 *
 * The component-level API is defined in FinancialsAuthResult.
 */
export const useAuth = (): SourceAuthResult => {
  const auth0Result = useAuth0();
  const defaultRedirectTo = `${window.location.origin}/documents`;
  return {
    ...auth0Result,
    logout: wrapDefaultRedirectTo(auth0Result.logout, defaultRedirectTo),
    user: sourceUser(auth0Result.user),
  };
};
