import App from "@app/App";
import Pendo from "@app/Pendo";
import { useLaunchDarklyFlags } from "@hooks/useFlags";
import { ReactQueryDevtools } from "react-query/devtools";

/**
 * This is used by the E2E tests to run conditionally based on the current
 * logged in user's feature flags.
 */
function LaunchDarklyFlags() {
  const sourceFlags = useLaunchDarklyFlags();

  return (
    <script id="launch-darkly-flags" type="application/json">
      {JSON.stringify(sourceFlags)}
    </script>
  );
}

export const Wrapper = () => {
  return (
    <>
      <Pendo />
      <App />
      <LaunchDarklyFlags />
      <ReactQueryDevtools position="bottom-right" />
    </>
  );
};
