export function getPendoInstance(): pendo.Pendo {
  return window.pendo;
}

export function isPendoAvailable() {
  return typeof getPendoInstance() !== "undefined";
}

/**
 * Initialization function for Pendo
 */
export function initializePendo(
  pendo: pendo.Pendo,
  consentState: CookieConsentState,
  userId: string,
) {
  const didNotConsentToStatistics = consentState.statistics !== true;

  pendo.initialize({
    disableCookies: didNotConsentToStatistics,
    excludeAllText: true,
    visitor: {
      id: userId,
      cookiebot_analytical: consentState.statistics,
    },
  });
}

export function updateOptions(
  pendo: pendo.Pendo,
  user: {
    studyteamUserId: string;
    email?: string;
  },
) {
  pendo.updateOptions({
    visitor: {
      id: user.studyteamUserId,
      email: user.email || null,
    },
  });
}
