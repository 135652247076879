import React from "react";
import "index.scss";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { initSentry } from "./config/sentry";
import { worker } from "@app/mocks/index";
import { Auth0ProviderWithHistory } from "@components/auth/Auth0ProviderWithHistory";
import { Wrapper } from "./Wrapper";
import { isDevEnabled } from "./lib/environment";

if (process.env.VITE_ENABLED_MOCKS === "true") {
  worker.start();
}

if (!isDevEnabled()) {
  initSentry();
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Wrapper />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById("root"),
);
