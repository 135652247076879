import { Spin } from "antd";

import "styles/objects/spinner.scss";

type SpinnerProps = {
  size?: "small" | "large" | "default";
};

const Spinner = ({ size }: SpinnerProps) => (
  <div className="study-sheet-container-spinner">
    <Spin size={size} />
  </div>
);

export default Spinner;
