import React from "react";
import { SourceFlagSet } from "@hooks/useFlags";

export type ConfigProps = {
  auth0Realm: () => string;
  doaApiRoot: string;
  esourceApiRoot: string;
  auth0ServiceConfig: () => any;
  apiGatewayRoot: string;
};

export type ConfigPropsWithFlags = ConfigProps & {
  flags: Partial<SourceFlagSet>;
};

const defaultConfigValues: ConfigPropsWithFlags = {
  doaApiRoot: "/",
  esourceApiRoot: "/",
  apiGatewayRoot: "/",
  auth0Realm: () => "",
  auth0ServiceConfig: () => {},
  flags: {},
};

export const ConfigProvider = React.createContext(defaultConfigValues);
export const useConfig = () => {
  const context = React.useContext(ConfigProvider);
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
