import { ANONYMOUS_USER } from "./common/featureFlags";

export type Auth0ServiceConfig = {
  domain: string;
  clientID: string;
  audience: string;
  issuer: string;
  scope: string;
  responseType: string;
};

export type FeatureFlagConfig = {
  clientSideID: string;
  context: { user: string; email?: string; anonymous: boolean };
  reactOptions: { useCamelCaseFlagKeys: boolean };
};

export type Config = {
  apiRoot: () => string;
  auth0Realm: () => string;
  auth0ServiceConfig: () => Auth0ServiceConfig;
  isNotProduction: () => boolean;
  featureFlagConfig: () => FeatureFlagConfig;
  doaAppRoot: string;
  esourceAppRoot: string;
  adminAppRoot: string;
  doaApiRoot: string;
  esourceApiRoot: string;
  apiGatewayRoot: string;
  appVersionMonitorTimeout: number;
};

const apiRoot = () => process.env.VITE_ESOURCE_API_ROOT || "/";

const auth0Realm = () => process.env.VITE_AUTH0_REALM as string;

const auth0ServiceConfig = (): Auth0ServiceConfig => ({
  domain: process.env.VITE_AUTH0_DOMAIN as string,
  clientID: process.env.VITE_AUTH0_CLIENT_ID as string,
  audience: process.env.VITE_AUTH0_AUDIENCE as string,
  issuer: process.env.VITE_AUTH0_ISSUER as string,
  scope: "openid profile email",
  responseType: "id_token" as string,
});

const isNotProduction = () => (process.env.VITE_ENV as string) !== "production";

const featureFlagConfig = (): FeatureFlagConfig => ({
  clientSideID: process.env.VITE_LD_CLIENT_SIDE_ID as string,
  // Ensure LD is initialized with a non-random anonymous user.
  context: { user: ANONYMOUS_USER, email: undefined, anonymous: true },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

const config: Config = {
  apiRoot,
  auth0Realm,
  auth0ServiceConfig,
  isNotProduction,
  featureFlagConfig,
  doaAppRoot: "/documents/doa",
  esourceAppRoot: "/documents",
  adminAppRoot: "/documents/admin",
  doaApiRoot: process.env.VITE_EDOA_API_ROOT || "/",
  esourceApiRoot: process.env.VITE_ESOURCE_API_ROOT || "/",
  apiGatewayRoot:
    process.env.VITE_API_GATEWAY_ROOT || "/api/api-gateway/graphql",
  appVersionMonitorTimeout: process.env.VITE_APP_VERSION_MONITOR_TIMEOUT
    ? parseInt(process.env.VITE_APP_VERSION_MONITOR_TIMEOUT, 10)
    : 900000, // 15 minutes default
};

export default config;
