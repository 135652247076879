import {
  LDMultiKindContext,
  LDSingleKindContext,
} from "launchdarkly-js-client-sdk";
import { User } from "@auth0/auth0-react";

export const ANONYMOUS_USER = "00000000-0000-0000-0000-000000000000";

export type LDUser = LDSingleKindContext & { email: string };

export type UserTrialContext = LDMultiKindContext & {
  user: LDUser;
  sponsorTrial: LDSingleKindContext;
};

export function getLDMultiContext(
  user?: User,
  trialId?: string,
): UserTrialContext | LDUser {
  const baseUser = {
    kind: "user",
    key: user?.sub || "",
    email: user?.email || "",
  };
  const ldUser =
    user?.sub === ANONYMOUS_USER
      ? {
          ...baseUser,
          anonymous: true,
        }
      : baseUser;

  if (trialId) {
    return {
      kind: "multi",
      user: ldUser,
      sponsorTrial: {
        kind: "sponsorTrial",
        key: trialId || "",
      },
    };
  } else {
    return ldUser;
  }
}
