import {
  DocumentTypes,
  DocumentWorkflowStatus,
} from "../../features/document-tables/types";
import mockReviewers from "./document-reviewers.json";

const baseDocument = {
  confirmed: true,
  createdAt: "2022-05-04T16:20:30.971832Z",
  filledInFormId: "fddb0663-c779-410c-8ff9-844863c00d73",
  id: "32ab9f87-3038-4198-abcf-bd61b499dd67",
  level0: null,
  level1: null,
  level2: null,
  name: "Demographics",
  originalDate: null,
  piSigned: true,
  signed: false,
  siteTrialPatientId: "7e0e7b93-d786-40ef-8854-9a5f429cc424",
  status: "ok",
  subjectId: "1003",
  type: DocumentTypes.DDCComplete,
  documentModifiedAt: "2022-05-04T16:20:50.080220Z",
  uploadedAt: "2022-05-04T16:20:30.971832Z",
  uploader: "Benjamin Aplin",
  piSignatureRequired: false,
  workflowAge: 0,
  openQueriesCount: 3,
  patientVisitIds: [],
  signatures: [],
  patientVisits: [],
};

export const inReviewDocument = {
  ...baseDocument,
  qcWorkflowStatus: DocumentWorkflowStatus.InReview,
  reviewer: mockReviewers[0],
  revisionAssignee: mockReviewers[1],
  documentModifiedAt: baseDocument.createdAt,
  queryCount: 3,
};

export const readyForReviewDocument = {
  ...baseDocument,
  qcWorkflowStatus: DocumentWorkflowStatus.ReadyForReview,
  workflowAge: 3,
};

export const approvedDocument = {
  ...inReviewDocument,
  qcWorkflowStatus: DocumentWorkflowStatus.Approved,
  queryCount: 0,
  workflowAge: 4,
};

export const allQCDocuments = [
  inReviewDocument,
  readyForReviewDocument,
  approvedDocument,
];
