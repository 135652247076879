import React from "react";
import { Auth } from "../../qc/types";

export const AuthProvider = React.createContext<Auth | undefined>(undefined);

/**
 * @deprecated Do not use the allowedActions returned from this hook. Please use useApplicationAccess.
 */
export const useUsersAuthProvider = () => {
  const context = React.useContext(AuthProvider);
  if (context === undefined) {
    throw new Error("useUsersAuthProvider must be used within a AuthProvider");
  }
  return context;
};
