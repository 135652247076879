import React, { Suspense } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { theme } from "./styles/theme";
import config, { type Config } from "./config";
import { auth0Context } from "@reifyhealth/picasso-auth0";
import { SourceAuthResult, useAuth } from "@hooks/auth";
import { useDatadogUser, useLDIdentify } from "./hooks";
import { SourceFlagSet, useLaunchDarklyFlags } from "@hooks/useFlags";
import Spinner from "@components/spinner";
import overrides from "overrides.json";

const EDOAAppRoutes = React.lazy(() => import("apps/edoa/AppRoutes"));
const LegacySourceAppRoutes = React.lazy(
  () => import("apps/legacy-esource/AppRoutes"),
);
const SourceAppRoutes = React.lazy(() => import("apps/esource/AppRoutes"));
const Adminapp = React.lazy(() => import("apps/admin/App"));

function SourceApp({
  config,
  flags,
  auth,
}: {
  config: Config;
  flags: SourceFlagSet;
  auth: SourceAuthResult;
}) {
  React.useEffect(() => {
    document.title = "eSource - StudyTeam";
  }, []);

  return (
    <Suspense fallback={<Spinner size="large" />}>
      <SourceAppRoutes
        config={config}
        flags={
          config.isNotProduction() && typeof overrides === "object"
            ? { ...flags, ...overrides }
            : flags
        }
        auth={auth}
      />
    </Suspense>
  );
}

function LegacySourceApp({
  config,
  flags,
  auth,
}: {
  config: Config;
  flags: SourceFlagSet;
  auth: SourceAuthResult;
}) {
  React.useEffect(() => {
    document.title = "eSource - StudyTeam";
  }, []);

  return (
    <Suspense fallback={<Spinner size="large" />}>
      <LegacySourceAppRoutes
        config={config}
        flags={
          config.isNotProduction() && typeof overrides === "object"
            ? { ...flags, ...overrides }
            : flags
        }
        auth={auth}
      />
    </Suspense>
  );
}

function AdminApp() {
  React.useEffect(() => {
    document.title = "Study Sheet Admin";
  }, []);

  return (
    <Suspense fallback={<Spinner size="large" />}>
      <Adminapp />
    </Suspense>
  );
}

function EDOAApp({
  config,
  flags,
  auth,
}: {
  config: Config;
  flags: SourceFlagSet;
  auth: SourceAuthResult;
}) {
  React.useEffect(() => {
    document.title = "DOA - StudyTeam";
  }, []);

  return (
    <Suspense fallback={<Spinner size="large" />}>
      <EDOAAppRoutes
        config={config}
        flags={
          config.isNotProduction() && typeof overrides === "object"
            ? { ...flags, ...overrides }
            : flags
        }
        auth={auth}
      />
    </Suspense>
  );
}

const App = () => {
  const auth = useAuth();
  useLDIdentify(auth?.user);
  useDatadogUser(auth?.user);

  const flags = useLaunchDarklyFlags();

  if (!auth?.isAuthenticated) {
    return <Spinner size="large" />;
  }

  const isLegacy: { current: boolean; previous: boolean } | undefined = flags[
    "CARE-9100-is-legacy-trial"
  ] as { current: boolean; previous: boolean } | undefined;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path={`${config.doaAppRoot}/*`}
          element={<EDOAApp config={config} auth={auth} flags={flags} />}
        />
        {isLegacy?.current ? (
          <Route
            path={`${config.esourceAppRoot}/*`}
            element={
              <LegacySourceApp config={config} auth={auth} flags={flags} />
            }
          />
        ) : (
          <Route
            path={`${config.esourceAppRoot}/*`}
            element={<SourceApp config={config} auth={auth} flags={flags} />}
          />
        )}
        <Route path={`${config.adminAppRoot}/*`} element={<AdminApp />} />
        <Route
          path="*"
          element={<Navigate replace to={config.esourceAppRoot} />}
        />
      </Routes>
    </ThemeProvider>
  );
};

export default withLDProvider(config.featureFlagConfig())(
  withAuthenticationRequired(App, {
    context: auth0Context,
    loginOptions: {
      authorizationParams: {
        // NOTE: This is required to tell our Auth0-side idle timeout logic
        // that a login triggered by this component is an initial user login,
        // rather than a subsequent token refresh.
        firstLogin: true,
      },
    },
  }),
);
