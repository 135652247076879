import {
  Key,
  SortOrder,
  SorterResult,
  TablePaginationConfig,
} from "@reifyhealth/picasso-pkg";
import {
  Dispatch,
  ReactNode,
  ReducerAction,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useParams } from "react-router-dom";
import useFlag from "../../../hooks";
import { Flags } from "@hooks/useFlags";

export type AllTableState = {
  allDocs: TableState;
  allBinders: TableState;
  individualBinder: TableState;
  queriesTable: TableState;
};

export enum TableActions {
  UPDATE_TABLE = "UPDATE_TABLE",
  UPDATE_FILTER = "UPDATE_FILTER",
  RESET_FILTER = "RESET_FILTER",
  RESET_ALL_TABLE = "RESET_ALL_TABLE",
  RESET_TABLE = "RESET_TABLE",
  UPDATE_PAGINATION = "UPDATE_PAGINATION",
  UPDATE_CURSOR_PAGINATION = "UPDATE_CURSOR_PAGINATION",
  CLEAR_CURSOR_PAGINATION_QUERY_STACK = "CLEAR_CURSOR_PAGINATION_QUERY_STACK",
}

export type TableType = keyof AllTableState;

export type FilterState = Record<string, TableFilterValue | null>;
export type QueryStack = [string, any[]][] | [];
export type CursorPaginationState = {
  next: string | null;
  after: string | null;
  queryStack: QueryStack;
};
export type SortState = (SorterResult<object> | SorterResult<object>[]) & {
  columnKey?: Key;
  order?: SortOrder;
  column?: any;
};
type ValueOf<T> = T[keyof T];

type CursorPaginationKey = "next" | "after" | "queryStack";
type CursorPaginationValue = ValueOf<CursorPaginationState>;
type FilterAction = {
  type: TableActions;
  payload: {
    tableType: TableType;
    newTableState?: TableState;
    filters?: FilterState;
    pagination?: TablePaginationConfig;
    cursorPagination?: CursorPaginationState;
    cursorPagKey?: CursorPaginationKey;
    cursorPagVal?: CursorPaginationValue;
  };
};

const initialCursorPagination: CursorPaginationState = {
  next: null,
  after: null,
  queryStack: [],
};

export type TableState = {
  cursorPagination?: CursorPaginationState;
  pagination: TablePaginationConfig;
  filters: FilterState;
  sorter: SortState;
};

export type TableFilterValue = any;

export const initialState: TableState = {
  cursorPagination: initialCursorPagination,
  pagination: { pageSize: 100 },
  filters: {},
  sorter: {
    columnKey: "uploadedAt",
    field: "uploadedAt",
    order: "descend",
  },
};

const buildAllTableState = (defaultTableState: TableState) => {
  return {
    allDocs: defaultTableState,
    allBinders: defaultTableState,
    individualBinder: defaultTableState,
    queriesTable: defaultTableState,
  };
};

export const initialTableState: AllTableState =
  buildAllTableState(initialState);

const resolveInitialState = (isDocumentsTableColumnUpdatesEnabled: boolean) => {
  const updatedInitialState: TableState = isDocumentsTableColumnUpdatesEnabled
    ? {
        ...initialState,
        sorter: {
          columnKey: "submittedAt",
          field: "submittedAt",
          order: "descend",
        },
      }
    : initialState;
  return updatedInitialState;
};

const buildReducer = (
  defaultTableState: TableState,
  defaultAllTableState: AllTableState,
) => {
  return (state: AllTableState, action: FilterAction) => {
    const {
      type,
      payload: {
        tableType,
        newTableState,
        filters,
        pagination,
        cursorPagKey,
        cursorPagVal,
      },
    } = action;
    switch (type) {
      case TableActions.UPDATE_TABLE:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            cursorPagination: initialCursorPagination,
            filters: {
              ...state[tableType].filters,
              ...newTableState?.filters,
            },
            pagination: newTableState?.pagination,
            sorter: newTableState?.sorter,
          },
        };
      case TableActions.UPDATE_FILTER:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            cursorPagination: initialCursorPagination,
            filters: {
              ...state[tableType].filters,
              ...filters,
            },
          },
        };
      case TableActions.UPDATE_PAGINATION:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            cursorPagination: initialCursorPagination,
            pagination: {
              ...state[tableType].pagination,
              ...pagination,
            },
          },
        };
      case TableActions.RESET_FILTER:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            cursorPagination: initialCursorPagination,
            filters: {},
          },
        };
      case TableActions.UPDATE_CURSOR_PAGINATION:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            cursorPagination: {
              ...state[tableType].cursorPagination,
              [cursorPagKey as string]: cursorPagVal,
            },
          },
        };
      case TableActions.CLEAR_CURSOR_PAGINATION_QUERY_STACK:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            cursorPagination: {
              ...state[tableType].cursorPagination,
              queryStack: [],
              after: null,
            },
          },
        };
      case TableActions.RESET_TABLE:
        return {
          ...state,
          [tableType]: defaultTableState,
        };
      case TableActions.RESET_ALL_TABLE:
        return defaultAllTableState;
      default:
        return state;
    }
  };
};

export type TableProviderDispatchType = Dispatch<
  ReducerAction<ReturnType<typeof buildReducer>>
>;

export type TableProviderValue = {
  dispatch?: TableProviderDispatchType;
  updateFilter: (tableType: TableType, filters: FilterState) => void;
  clearFilters: (tableType: TableType) => void;
  clearQueryStack: (tableType: TableType) => void;
  updateCursorPagination: (
    tableType: TableType,
    cursorPagKey: CursorPaginationKey,
    cursorPagVal: CursorPaginationValue,
  ) => void;
  state: AllTableState;
  updateTable: (tableType: TableType, newTableState: TableState) => void;
  updatePagination: (
    tableType: TableType,
    pagination: TablePaginationConfig,
  ) => void;
};

export const defaultState: TableProviderValue = {
  state: initialTableState,
  updateFilter: () => {},
  clearFilters: () => {},
  updateTable: () => {},
  updatePagination: () => {},
  updateCursorPagination: () => {},
  clearQueryStack: () => {},
};

export const TableContext = createContext(defaultState);

export const TableProvider = ({
  children,
  testOptions = {},
}: {
  children: ReactNode;
  testOptions?: Partial<TableProviderValue>;
}) => {
  const isDocumentsTableColumnUpdatesEnabled = useFlag(
    Flags.DOCUMENTS_TABLE_COLUMN_UPDATES,
  ) as boolean;

  const updatedInitialState: TableState = resolveInitialState(
    isDocumentsTableColumnUpdatesEnabled,
  );

  const updatedInitialTableState = isDocumentsTableColumnUpdatesEnabled
    ? buildAllTableState(updatedInitialState)
    : initialTableState;

  const tableReducer = buildReducer(
    updatedInitialState,
    updatedInitialTableState,
  );

  const [state, dispatch] = useReducer(tableReducer, updatedInitialTableState);

  // We are getting the ids this way because the provider
  // is not a child of the Routes provider (`useParams` doesn't
  // return the ids by variable name)
  const [, trialId, , siteId, siteTrialPatientId] =
    useParams()?.["*"]?.split("/") || [];

  const subjectId =
    siteTrialPatientId?.length === 36 ? siteTrialPatientId : null;

  useEffect(() => {
    dispatch({
      type: TableActions.RESET_ALL_TABLE,
      payload: {},
    } as FilterAction);
  }, [trialId, siteId]);

  useEffect(() => {
    dispatch({
      type: TableActions.RESET_TABLE,
      payload: { tableType: "individualBinder" },
    });
  }, [subjectId]);

  const updateFilter = (tableType: TableType, filters: FilterState) => {
    dispatch({
      type: TableActions.UPDATE_FILTER,
      payload: { tableType, filters },
    });
  };

  const updatePagination = (
    tableType: TableType,
    pagination: TablePaginationConfig,
  ) => {
    dispatch({
      type: TableActions.UPDATE_PAGINATION,
      payload: { tableType, pagination },
    });
  };

  const updateTable = (tableType: TableType, newTableState: TableState) => {
    dispatch({
      type: TableActions.UPDATE_TABLE,
      payload: { tableType, newTableState },
    });
  };

  const clearFilters = (tableType: TableType) => {
    dispatch({
      type: TableActions.RESET_FILTER,
      payload: { tableType },
    });
  };
  // TODO: It probably should not be exposed.
  // Instead it could be managed only by the provider
  // and call it whenever needed.
  const clearQueryStack = (tableType: TableType) => {
    dispatch({
      type: TableActions.CLEAR_CURSOR_PAGINATION_QUERY_STACK,
      payload: { tableType },
    });
  };
  const updateCursorPagination = (
    tableType: TableType,
    cursorPagKey: CursorPaginationKey,
    cursorPagVal: CursorPaginationValue,
  ) => {
    dispatch({
      type: TableActions.UPDATE_CURSOR_PAGINATION,
      payload: { tableType, cursorPagKey, cursorPagVal },
    });
  };

  const providerValue = {
    state,
    dispatch,
    updateFilter,
    clearFilters,
    updateTable,
    updatePagination,
    updateCursorPagination,
    clearQueryStack,
    ...testOptions,
  };

  return (
    <TableContext.Provider value={providerValue}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableProvider = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error("useTableProvider must be used within a TableProvider");
  }
  return context;
};
